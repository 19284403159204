import { firstValueFrom } from 'rxjs';

import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import {
  TranslocoModule,
  TranslocoService,
  provideTransloco,
} from '@ngneat/transloco';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';

import { TranslocoHttpLoader } from './transloco-loader';

export function setMainLanguage(
  injector: Injector,
  transloco: TranslocoService,
  auth: PmAuthService,
) {
  return async function () {
    const config = injector.get(APP_CONFIG) as AppConfig;

    const {
      drupal: { langcode },
    } = await auth.getDecodedToken();
    const lang = langcode ?? config.localeCode ?? 'en';
    transloco.setActiveLang(lang);
    return firstValueFrom(transloco.load(lang));
  };
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [
          'en',
          'es',
          'pt-pt',
          'pl',
          'uk',
          'ar',
          'cs',
          'de',
          'el',
          'fr',
          'hi',
          'hu',
          'it',
          'lt',
          'nl',
          'ro',
          'sl',
          'sv',
          'tr',
          'zh',
        ],
        defaultLang: 'en',
        reRenderOnLangChange: true,
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: setMainLanguage,
      deps: [Injector, TranslocoService, PmAuthService],
    },
  ],
})
export class TranslocoRootModule {}
